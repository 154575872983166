import styles from './styles.module.css'

const colors = [
    "#26B5E0",
    "#1ECE6D",
    "#F2C400",
    "#9B56BA",
    "#EA4935"
]

const WordsLogo = () => {
    return (
        <div className={styles.container}>
            {
                "WORDS".split('').map((letter, index) => (
                    <span 
                    key={index} 
                    className={styles.letter} 
                    style={{backgroundColor:colors[index]}}>
                        {letter}
                    </span>
                ))
            }
        </div>
    )
}

export default WordsLogo