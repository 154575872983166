import styles from './styles.module.css'
import WordsLogo from '../WordsLogo/WordsLogo'

const Header = () => {
    return (
        <header className={styles.header}>
            <WordsLogo/>
        </header>
    )
}

export default Header