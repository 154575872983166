import styles from './styles.module.css'
import { useState } from 'react'
import arrowLeft from './assets/arrowLeft.png'
import arrowRight from './assets/arrowRight.png'

const Photorama = () => {

    const [photo, setPhoto] = useState(0)

    const nextPhoto = () => {
        setPhoto((photo + 1) % 5)
    }

    const previousPhoto = () => {
        setPhoto((photo + 4) % 5)
    }

    return (
         <div className={styles.container}>
            <div className={styles.row}>
                <img src={arrowLeft} alt='previous' className={styles.arrow} onClick={previousPhoto}/>
                <img src={`/assets/screens/screen_${photo}.jpg`} alt='1' className={styles.capture}/>
                <img src={arrowRight} alt='next' className={styles.arrow} onClick={nextPhoto}/>
            </div>
        </div>
    )
}

export default Photorama