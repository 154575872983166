// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_header__\\+2-y5 {
    background-color: var(--dark-blue);
    color: var(--white-app);
    padding: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":[".header {\n    background-color: var(--dark-blue);\n    color: var(--white-app);\n    padding: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles_header__+2-y5`
};
export default ___CSS_LOADER_EXPORT___;
