import Header from "../../Components/Header/Header"
import Menu from "../../Components/Menu/Menu"
import styles from './styles.module.css'

const SupportScreen = () => {
    return (
        <div className={styles.container}>
            <Header />
            <Menu selected={1} />
            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    <h1>Privacy Policy</h1>
                    <p>This Privacy Policy describes how your information is handled when you use the "Words Deluxe" mobile application (the "App").</p>

                    <h2>Information We Collect</h2>
                    <p>Words Deluxe is designed with your privacy in mind. We do not collect, store, or transmit any personal information or usage data from your device.</p>

                    <h2>Local Data Storage</h2>
                    <p>The App saves game state information, such as completed stages and progress, locally on your device. This data is stored only on your device and is not transmitted to our servers or any third parties.</p>

                    <h2>How We Use Your Information</h2>
                    <p>Since we do not collect any personal information or usage data, we do not use your information for any purpose.</p>

                    <h2>Third-Party Access</h2>
                    <p>No third parties have access to any information stored locally on your device by our App.</p>

                    <h2>Your Rights</h2>
                    <p>As we do not collect any personal data, there is no personal information for us to provide, correct, or delete. You have full control over the app data stored on your device.</p>

                    <h2>Data Deletion</h2>
                    <p>You can delete all locally stored game data by uninstalling the App from your device.</p>

                    <h2>Changes to This Policy</h2>
                    <p>We may update this privacy policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically.</p>

                    <h2>Children's Privacy</h2>
                    <p>Our App does not collect personal information from anyone, including children under the age of 13.</p>

                    <h2>Contact Us / Support</h2>
                    <p>If you have any questions about this Privacy Policy, or needs support, please contact us at support@words-deluxe.com.</p>
                </div>
            </div>
        </div>
    )
}

export default SupportScreen