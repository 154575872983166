import Header from '../../Components/Header/Header'
import Photorama from '../../Components/Photorama/Photorama'
import StoreButton from '../../Components/StoreButton/StoreButton'
import Menu from '../../Components/Menu/Menu'
import styles from './styles.module.css'

const HomeScreen = () => {
    return (
        <div className={styles.container}>
            <Header/>
            <Menu selected={0}/>
            <p className={styles.introduction}>Words Deluxe is an addictive game where you have to find words on themed grids!</p>
            <Photorama/>
            <div className={styles.storeSection}>
                <StoreButton/>
            </div>
        </div>
    )
}

export default HomeScreen