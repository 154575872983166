// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__f-lwf {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.styles_item__\\+Z02N {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 16;
    color: var(--dark-blue);
    padding: 10px;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
}

.styles_item__\\+Z02N:hover {
    cursor: pointer;
    text-decoration: none;
}

.styles_selected__8IKJ7 {
    background-color: var(--dark-blue);
    color: var(--white-app);
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Menu/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,yCAAyC;IACzC,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,kCAAkC;IAClC,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 10px;\n}\n\n.item {\n    font-family: 'Helvetica Neue', sans-serif;\n    font-size: 16;\n    color: var(--dark-blue);\n    padding: 10px;\n    padding-left: 16px;\n    padding-right: 16px;\n    text-decoration: none;\n}\n\n.item:hover {\n    cursor: pointer;\n    text-decoration: none;\n}\n\n.selected {\n    background-color: var(--dark-blue);\n    color: var(--white-app);\n    border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__f-lwf`,
	"item": `styles_item__+Z02N`,
	"selected": `styles_selected__8IKJ7`
};
export default ___CSS_LOADER_EXPORT___;
