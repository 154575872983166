// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__pKQ7n {
    display: flex;
    align-items: center;
    justify-content: center;
}

.styles_letter__pugKH {
    font-family: 'Helvetica Neue', sans-serif;
    background-color: red;
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;   
    justify-content: center;
    font-size: 14px;
    margin-left: 3px;
    margin-right: 3px;
}`, "",{"version":3,"sources":["webpack://./src/Components/WordsLogo/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,yCAAyC;IACzC,qBAAqB;IACrB,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.letter {\n    font-family: 'Helvetica Neue', sans-serif;\n    background-color: red;\n    display: inline-block;\n    width: 32px;\n    height: 32px;\n    border-radius: 16px;\n    display: flex;\n    align-items: center;   \n    justify-content: center;\n    font-size: 14px;\n    margin-left: 3px;\n    margin-right: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__pKQ7n`,
	"letter": `styles_letter__pugKH`
};
export default ___CSS_LOADER_EXPORT___;
