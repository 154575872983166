import image from './assets/image.png'
import styles from './styles.module.css'

const StoreButton = () => {

    const onClick = () => {
        window.open('https://apps.apple.com/fr/app/words-deluxe/id6645736576', '_blank')
    }

    return (
        <div onClick={onClick}>
            <img src={image} alt='store' className={styles.container}/>
        </div>
    )
}

export default StoreButton