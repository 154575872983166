import Header from "../../Components/Header/Header"
import Menu from "../../Components/Menu/Menu"
import styles from './styles.module.css'

const CGUScreen = () => {
    return (
        <div className={styles.container}>
            <Header />
            <Menu selected={1} />
            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    <h1>Terms and Conditions of Use</h1>
                    <h2>Acceptance of Terms</h2>
                    <p>By downloading, installing, or using the mobile application "Words Deluxe" (hereinafter referred to as "the Application"), you agree to be bound by these Terms and Conditions of Use (T&Cs). If you do not accept these terms, please do not use the Application.</p>
                    <h2>Service Description</h2>
                    <p>Words Deluxe is a word game application available on mobile devices. The Application offers various game modes and features related to finding words in grids.</p>
                    <h2>Intellectual Property</h2>
                    <p>The Application, including its content, features, and interface, is the exclusive property of Didier Brun and is protected by intellectual property laws.</p>
                    <p>Brand names, logos, or other intellectual property elements used in the game are not affiliated with Words Deluxe. Their use in the context of the game does not in any way constitute an association, sponsorship, or endorsement by the owners of these brands.</p>
                    <h2>Use of the Application</h2>
                    <p>You agree to use the Application in accordance with applicable laws and these T&Cs.</p>
                    <p>It is forbidden to modify, adapt, or hack the Application. It is prohibited to use the Application for illegal or unauthorized purposes.</p>
                    <h2>Modifications and Updates</h2>
                    <p>Didier Brun reserves the right to modify, update, or discontinue the Application at any time, with or without notice.</p>
                    <p>These T&Cs may be updated periodically. The most recent version will always be available in the Application.</p>
                    <h2>Limitation of Liability</h2>
                    <p>The application is provided "as is" without warranty of any kind.</p>
                    <p>Didier Brun will not be liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use the Application.</p>
                    <h2>Contact</h2>
                    <p>For any questions regarding these T&Cs, please contact us at support@words-deluxe.com</p>
                </div>
            </div>
        </div>
    )
}

export default CGUScreen