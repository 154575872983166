// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__PAZZ8 {
    font-family: 'Helvetica Neue', sans-serif;
    color: var(--dark-blue);
    font-size: 14px;
    padding-bottom: 40px;
}

.styles_content__Hn3cY {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 800px;
}

.styles_content__Hn3cY h1 {
    font-size: 22px;
}

.styles_content__Hn3cY h2 {
    font-size: 18px;
    margin-bottom: -5px;
}

.styles_content__Hn3cY p {
    margin: 0px;
    line-height: 20px;
    margin-top: 10px;
}

.styles_contentWrapper__GtN2e {
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Screens/CGUScreen/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,uBAAuB;IACvB,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".container {\n    font-family: 'Helvetica Neue', sans-serif;\n    color: var(--dark-blue);\n    font-size: 14px;\n    padding-bottom: 40px;\n}\n\n.content {\n    padding-left: 20px;\n    padding-right: 20px;\n    max-width: 800px;\n}\n\n.content h1 {\n    font-size: 22px;\n}\n\n.content h2 {\n    font-size: 18px;\n    margin-bottom: -5px;\n}\n\n.content p {\n    margin: 0px;\n    line-height: 20px;\n    margin-top: 10px;\n}\n\n.contentWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__PAZZ8`,
	"content": `styles_content__Hn3cY`,
	"contentWrapper": `styles_contentWrapper__GtN2e`
};
export default ___CSS_LOADER_EXPORT___;
