import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './variables.css'
import HomeScreen from './Screens/HomeScreen/HomeScreen'
import CGUScreen from './Screens/CGUScreen/CGUScreen'
import SupportScreen from './Screens/SupportScreen/SupportScreen'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen/>,
  },
  {
    path: "/privacy",
    element: <SupportScreen/>
  },
  {
    path: "/terms",
    element: <SupportScreen/>
  }
])

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)