// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__NyjQQ {
    font-size: 14px;
}

.styles_storeSection__WPDG8 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.styles_introduction__FTaT2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: light;
    color: var(--dark-blue);
    line-height: 20px;
    font-size: 16px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Screens/HomeScreen/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yCAAyC;IACzC,kBAAkB;IAClB,uBAAuB;IACvB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".container {\n    font-size: 14px;\n}\n\n.storeSection {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 20px;\n}\n\n.introduction {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-family: 'Helvetica Neue', sans-serif;\n    font-weight: light;\n    color: var(--dark-blue);\n    line-height: 20px;\n    font-size: 16px;\n    padding-left: 40px;\n    padding-right: 40px;\n    text-align: center;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__NyjQQ`,
	"storeSection": `styles_storeSection__WPDG8`,
	"introduction": `styles_introduction__FTaT2`
};
export default ___CSS_LOADER_EXPORT___;
