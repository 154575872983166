// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__jzE1s {
    width: 180px;
}`, "",{"version":3,"sources":["webpack://./src/Components/StoreButton/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB","sourcesContent":[".container {\n    width: 180px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__jzE1s`
};
export default ___CSS_LOADER_EXPORT___;
