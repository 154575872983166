import styles from './styles.module.css'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

const items = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Privacy policy",
        path: "/privacy"
    }
]

const Menu = ({ selected }) => {

    const navigate = useNavigate()

    const clickItem = (index) => {
        navigate(items[index].path)
    }

    return (
        <div className={styles.container}>
            {
                items.map((item, index) => {
                    return (
                        <div 
                        key={index} 
                        className={classNames(styles.item, index == selected && styles.selected)}
                        onClick={() => { clickItem(index) }}
                        >
                            {item.label}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Menu