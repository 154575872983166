// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --dark-blue: #2D3D51;
    --white-app: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/variables.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,oBAAoB;AACxB","sourcesContent":[":root {\n    --dark-blue: #2D3D51;\n    --white-app: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
