// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__lIF0A {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
}

.styles_capture__diinX {
    width: 60vw;
    margin: 10px;
    max-width: 400px;
}

.styles_row__ffUWY {
    display: flex;
    align-items: center;
}

.styles_arrow__T3CW4{
    width: 40px;
    height: 40px;
}

.styles_arrow__T3CW4:hover {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/Photorama/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    padding-top: 20px;\n}\n\n.capture {\n    width: 60vw;\n    margin: 10px;\n    max-width: 400px;\n}\n\n.row {\n    display: flex;\n    align-items: center;\n}\n\n.arrow{\n    width: 40px;\n    height: 40px;\n}\n\n.arrow:hover {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__lIF0A`,
	"capture": `styles_capture__diinX`,
	"row": `styles_row__ffUWY`,
	"arrow": `styles_arrow__T3CW4`
};
export default ___CSS_LOADER_EXPORT___;
